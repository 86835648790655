export function validate(value, rules) {
  if (!rules.isRequired && isEmpty(value)) {
    return '';  // If the field is not required it may be empty, otherwise the value must be valid basaed on other rules
  }

  for (let rule in rules) {
    switch (rule) {
      case 'isEmail':
        if (!emailValidator(value))
          return 'Please enter a valid email';
        break;
      case 'isPhone':
        if (!phoneValidator(value))
          return 'Please enter a valid phone number';
        break;
      case 'minLength':
        if (!minLengthValidator(value, rules[rule]))
          return `At least ${rules[rule]} characters must be entered`;
        break;
      case 'maxLength':
        if (!maxLengthValidator(value, rules[rule]))
          return `Must not enter more than ${rules[rule]} characters`;
        break;
      case 'isRequired':
        if (!requiredValidator(value))
          return `This field is required`;
        break;
      default:
        break;
    }
  }

  return '';
}

const isEmpty = value => value.trim().length === 0;

const minLengthValidator = (value, minLength) => {
  return value.trim().length >= minLength;
}

const maxLengthValidator = (value, maxLength) => {
  return value.trim().length <= maxLength;
}

const requiredValidator = value => {
  return value.trim() !== '';
}

const emailValidator = value => {
  // regex from http://emailregex.com/
  // eslint-disable-next-line no-useless-escape
  var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(String(value).toLowerCase());
}

const phoneValidator = value => {
  // regex from http://phoneregex.com/
  var pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
  return pattern.test(String(value).toLowerCase());
}

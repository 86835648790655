import React, { useState } from 'react';
import { TextInput, validate } from './forms';
import style from './contactForm.module.scss';
import { PrimaryButton } from '../components/buttons';
import { Title } from '../components/typography';

const defaultState = {
  name: {
    label: 'Name',
    name: 'name',
    placeholder: 'What is your name?',
    value: '',
    touched: false,
    errorMessage: '',
    validationRules: {
      isRequired: true,
    },
    autoComplete: 'name',
  },
  title: {
    label: 'Title',
    name: 'title',
    placeholder: 'What is your title?',
    value: '',
    touched: false,
    errorMessage: '',
    validationRules: {
      isRequired: true,
    },
  },
  agency: {
    label: 'Agency',
    name: 'agency',
    placeholder: 'What agency are you with?',
    value: '',
    touched: false,
    errorMessage: '',
    validationRules: {
      isRequired: true,
    },
  },
  email: {
    label: 'Email',
    name: 'email',
    placeholder: 'What is your email?',
    value: '',
    touched: false,
    errorMessage: '',
    validationRules: {
      isEmail: true,
      isRequired: true,
    },
    autoComplete: 'email',
  },
  phone: {
    label: 'Phone Number',
    name: 'phone',
    placeholder: 'What is your phone number?',
    value: '',
    touched: false,
    errorMessage: '',
    validationRules: {
      isPhone: true,
      isRequired: true,
    },
    autoComplete: 'tel',
  },
  comment: {
    label: 'Comments',
    name: 'comment',
    placeholder: 'What service or product are you interested in discussing with us?',
    value: '',
    touched: false,
    errorMessage: '',
    validationRules: {
      isRequired: false,
    }
  },
  isFormValid: false,
};

function checkFormValid(s): boolean {
  return !validate(s.name.value, s.name.validationRules)
    && !validate(s.title.value, s.title.validationRules)
    && !validate(s.agency.value, s.agency.validationRules)
    && !validate(s.email.value, s.email.validationRules)
    && !validate(s.phone.value, s.phone.validationRules)
    && !validate(s.comment.value, s.comment.validationRules);
}

const Field = ({ definition, onChange, onBlur }) => {
  return <TextInput
    key={definition.name}
    label={<span>{definition.label}{definition.validationRules.isRequired && <span className={style.requiredText}> *</span>}</span>}
    name={definition.name}
    autoComplete={definition.autoComplete}
    placeholder={definition.placeholder}
    value={definition.value}
    onChange={onChange}
    touched={definition.touched ? 'true' : undefined}
    errorMessage={definition.errorMessage}
    onBlur={onBlur}
    required={definition.validationRules.isRequired ? true : undefined}
  />
};

const TextBox = ({ definition, onChange, onBlur }) => {
  return <label className={style.inputLabel}>{definition.label}
    <textarea className={style.textArea}
      key={definition.name}
      name={definition.name}
      autoComplete={definition.autoComplete}
      placeholder={definition.placeholder}
      value={definition.value}
      onChange={onChange}
      onBlur={onBlur}
      required={definition.validationRules.isRequired ? true : undefined}
    />
  </label>
};

const ContactForm = () => {
  const [state, setState] = useState(defaultState);
  const formEndpoint = `https://formspree.io/f/${process.env.GATSBY_FORMSPREE_FORM_KEY}`;
  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    const updatedState = {
      ...state[name],
      value: value,
      touched: true,
      errorMessage: validate(value, state[name].validationRules),
    };

    const newState = { ...state, [name]: updatedState};
    newState.isFormValid = checkFormValid(newState);

    setState(newState);
  }

  function handleBlur(e) {
    const name = e.target.name;
    const value = e.target.value;

    const updatedState = {
      ...state[name],
      touched: true,
      errorMessage: validate(value, state[name].validationRules),
    };

    const newState = { ...state, [name]: updatedState};
    newState.isFormValid = checkFormValid(newState);
    setState(newState);
  }


  return (
    <form
      className={style.styledForm}
      name="contact"
      action={formEndpoint} 
      method="POST"
    >
      <Title className={style.titleSpacing}>Request Form</Title>
      <input type="hidden" name="subject" value="A consultation has been requested from the Centeva Website" />
      
      <Field definition={state.name} onChange={handleChange} onBlur={handleBlur} />
      <Field definition={state.title} onChange={handleChange} onBlur={handleBlur} />
      <Field definition={state.agency} onChange={handleChange} onBlur={handleBlur} />
      <Field definition={state.email} onChange={handleChange} onBlur={handleBlur} />
      <Field definition={state.phone} onChange={handleChange} onBlur={handleBlur} />
      <TextBox definition={state.comment} onChange={handleChange} onBlur={handleBlur} />
      <div className={style.shiftButtonDown}>
        <PrimaryButton className={style.submitButton} type="submit" disabled={!state.isFormValid}>Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default ContactForm;

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ContactForm from '../components/contactForm';
import { Icon, TwitterIcon } from '../components/icon';
import PageWithHeader from '../components/pageWithHeader';
import { BodyText, Subheader, Title } from '../components/typography';
import { DataComponent } from '../helpers/dataHelpers';
import style from './contact.module.scss';

const YourVoiceHeard = () => (
  <div className={style.voiceHeardContainer}>
    <Title className={style.voiceHeardTitle}>Your Voice Heard</Title>
    <Subheader>Clear Communication from Coast to Coast</Subheader>
    <div>
      <p className={`${style.voiceHeardDescription}`}>
        Our offices and staff are located across the nation in the Washington, D.C. Metro Areas, the Greater Salt Lake City Region,
        as well as within numerous agency buildings. We take pride in responding to our customers' needs and delivering world-class
        support and service quickly and efficiently.
      </p>
      <p className={`${style.voiceHeardDescription}`}>
        <b>How can we help you?</b>
        <br /><br />
        We'd love to hear from you and discuss how we might help your organization. Please submit a Request Form with your contact
        information and a professional from Centeva will be in touch right away.
      </p>
    </div>
  </div>
);

const ContactList = () => (
  <div className={style.contactMethodList}>
    <div className={style.contactMethod}>
      <div className={style.iconContainer}>
        <Icon className={style.iconFill}>email</Icon>
      </div>
      <Subheader className={style.contactHeader}>E-mail</Subheader>
      <BodyText><a href='mailto:federal@centeva.com'>federal@centeva.com</a></BodyText>
    </div>
    <div className={style.contactMethod}>
      <div className={style.iconContainer}>
        <Icon className={style.iconFill}>phone</Icon>
      </div>
      <Subheader className={style.contactHeader}>Phone</Subheader>
      <BodyText>+1 (801) 967-0077</BodyText>
    </div>
    <div className={style.contactMethod}>
      <div className={style.iconContainer}>
        <Icon className={style.iconFill}>business</Icon>
      </div>
    <Subheader className={style.contactHeader}>Headquarters</Subheader>
    <BodyText>
      155 Church St.,
      <br />
      Ste 210
      <br />
      Logan, UT 84321
    </BodyText>
  </div>
  <div className={style.contactMethod}>
    <div className={style.iconContainer}>
      <TwitterIcon fill={`white`} />
    </div>
    <Subheader className={style.contactHeader}>Twitter</Subheader>
      <BodyText><a target='_blank' href='https://twitter.com/centeva'>@centeva</a></BodyText>
    </div>
  </div>
);

const Contact = () => {
  const data = useStaticQuery<GatsbyTypes.ContactQuery>(graphql`
  query Contact {
      headerImage: file(name: {eq: "202004-centeva-locations"}) {
        publicURL
      }
      pageImage: file(name: {eq: "Centeva Logan"}) {
        childImageSharp {
          fixed(height: 300, cropFocus: CENTER, quality: 100, fit: COVER, jpegQuality: 100, pngQuality: 100, webpQuality: 100) {
            src
          }
        }
      }
    }
  `);

    return <ContactInner data={data}></ContactInner>
};

export default Contact;

const ContactInner: DataComponent<GatsbyTypes.ContactQuery> = ({data}) => {
  return (
    <PageWithHeader
      title="Contact Centeva"
      description="Centeva has offices throughout the greater Salt Lake City and Washington, D.C. metro areas."
      headerImage={data.headerImage.publicURL}
      image={data?.pageImage?.childImageSharp?.fixed?.src}
    >
      <article>
        <div>
          <div className={style.contactContainer}>
            <YourVoiceHeard />
            <ContactForm />
          </div>
        </div>
        <div className={style.contactList}>
          <ContactList />
        </div>
      </article>
      <div className={style.articleFooter}>
        <h3>Join Centeva's Team!</h3>
        <a href="https://centeva.applytojob.com/"><button>View Jobs</button></a>
      </div>
    </PageWithHeader>
  );
}
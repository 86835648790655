import React from 'react';
import style from './textInput.module.scss';

function inErrorState(props) {
  return props.touched && !!props.errorMessage;
}

export function TextInput(props) {
  const inputProps = { ...props };
  delete inputProps.type;
  delete inputProps.label;
  delete inputProps.touched;
  delete inputProps.errorMessage;

  return (
    <label className={style.inputLabel}>{props.label}
      <input className={`${style.inputField} ${inErrorState(props) && style.errorBorder}`}
        type={props.type || 'text'}
        {...inputProps}
      />
      {/* {props.errorMessage && <div className={style.validationMessage} role="alert">
        {props.errorMessage || '\xa0'}
      </div>
      } */}
    </label>
  );
}
